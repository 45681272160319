import Vue from "vue";
import Router from "vue-router";
import { store } from "./store";

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      meta: { auth: true },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "home" */ "./views/Home.vue")
      component: () =>
        import(/* webpackChunkName: "account" */ "./views/Account.vue"),
    },
    {
      path: "/login",
      name: "login",
      meta: { auth: false },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/Login.vue"),
    },
    // {
    //   path: "/account",
    //   name: "account",
    //   meta: { auth: true },
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "account" */ "./views/Account.vue"),
    // },
    {
      path: "/app-download",
      name: "app-download",
      meta: { auth: true },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "appDownload" */ "./views/AppDownload.vue"),
    },
    {
      path: "/account/:paymentstatus",
      name: "account-success",
      meta: { auth: true },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "account" */ "./views/Account.vue"),
    },
    {
      path: "/verifyUser/:hash",
      name: "verify-user",
      meta: { auth: false },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "verifyUser" */ "./views/Verify.vue"),
    },
    {
      path: "/confirmNewsletter/:hash",
      name: "confirm-newsletter",
      meta: { auth: false },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "confirmNewsletter" */ "./views/ConfirmNewsletter.vue"
        ),
    },
    {
      path: "*",
      name: "home",
      meta: { auth: true },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "home" */ "./views/Home.vue")
      component: () =>
        import(/* webpackChunkName: "account" */ "./views/Account.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth === true) {
    if (!store.getters.isAuthenticated) {
      next({ name: "login" });
      return;
    }
  }

  if (to.name === "login") {
    if (store.getters.isAuthenticated) {
      next({ name: "home" });
      return;
    }
  }
  next();
});

export default router;
