<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        © {{ currentYear }} Wörterfabrik für unterstützte Kommunikation UG
        (haftungsbeschränkt)
        <br />
        <a href="https://eis-app.de/impressum/">Impressum</a> -
        <a href="https://eis-app.de/datenschutz/">Datenschutz</a> -
        <a href="https://eis-app.de/agb/">AGB</a>
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",

  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>
