import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import createPersistedState from "vuex-persistedstate";
import { db, auth } from "./db";
import router from "./router";
import Settings from "@/settings.js";
import { DialogProgrammatic as Dialog } from "buefy";
const axios = require("axios");

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    currentView: "Home",
    user: null,
    userData: null,
    authenticated: false,
    passwordForgotten: false,
    resetPasswordSentSuccess: false,
    loginError: false,
    token: null,
    emailVerifiedSuccess: false,
    emailVerifiedMessage: "",
    newsletterConfirmedSuccess: false,
    newsletterConfirmedMessage: "",
    checkout: null,
    checkoutStep: 1,
    checkoutPlan: null,
    subscription: null,
    payments: null,
    processingSubscription: false,
    // auth
  },
  mutations: {
    setCurrentView(state, view) {
      state.currentView = view;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUserData(state, userData) {
      state.userData = userData;
    },
    setToken(state, token) {
      state.token = token;
    },
    setIsAuthenticated(state, authenticated) {
      state.authenticated = authenticated;
      state.loginError = false;
    },
    setUserLoggedOut(state) {
      state.authenticated = false;

      state.user = null;
      state.token = null;
    },
    setPasswordForgotten(state, passwordForgotten) {
      state.passwordForgotten = passwordForgotten;
    },
    setUserLoginError(state) {
      state.loginError = true;
    },
    setResetPasswordSentSuccess(state, resetPasswordSentSuccess) {
      state.resetPasswordSentSuccess = resetPasswordSentSuccess;
    },
    setEmailVerified(state, result) {
      state.emailVerifiedSuccess = result.success;
      state.emailVerifiedMessage = result.message;
    },
    setEmailVerifiedSuccess(state, success) {
      state.emailVerifiedSuccess = success;
    },
    setEmailVerifiedMessage(state, message) {
      state.emailVerifiedMessage = message;
    },
    setNewsletterConfirmed(state, result) {
      state.newsletterConfirmedSuccess = result.success;
      state.newsletterConfirmedMessage = result.message;
    },
    setNewsletterConfirmedSuccess(state, success) {
      state.newsletterConfirmedSuccess = success;
    },
    setNewsletterConfirmedMessage(state, message) {
      state.newsletterConfirmedMessage = message;
    },
    setCheckout(state, checkout) {
      state.checkout = checkout;
    },
    setCheckoutStep(state, step) {
      state.checkoutStep = step;
    },
    setCheckoutPlan(state, plan) {
      state.checkoutPlan = plan;
    },
    setSubscription(state, subscription) {
      state.subscription = subscription;
    },
    setPayments(state, payments) {
      state.payments = payments;
    },
    setProcessingSubscription(state, processing) {
      state.processingSubscription = processing;
    },
    // setAuth(state, auth) {
    //   state.auth = auth;
    // },
    ...vuexfireMutations,
  },
  actions: {
    setCurrentView({ commit }, view) {
      commit("setCurrentView", view);
    },
    gotoNextCheckoutStep({ state, commit }) {
      commit("setCheckoutStep", state.checkoutStep + 1);
    },
    gotoLogin({ commit }) {
      commit("setResetPasswordSentSuccess", false);
      commit("setPasswordForgotten", false);
    },
    passwordForgotten({ commit }, { passwordForgotten }) {
      commit("setResetPasswordSentSuccess", false);
      commit("setPasswordForgotten", passwordForgotten);
    },
    userLogin({ commit, dispatch }, { email, password }) {
      auth
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          commit("setUser", user);
          dispatch("bindUser", user);
          commit("setIsAuthenticated", true);
          return true;
        })
        .catch(() => {
          commit("setUserLoginError");
          return false;
        })
        .then((loggedIn) => {
          if (loggedIn) {
            auth.currentUser.getIdToken().then(function(token) {
              commit("setToken", token);
            });
            router.go({ name: "home" });
          } else {
            commit("setUserLoginError");
          }
          return loggedIn;
        });
    },
    getSubscription({ commit, state }) {
      if (!state.userData) {
        return;
      }
      let current_subscription = state.userData.current_subscription;
      if (current_subscription) {
        if (current_subscription.payment_provider === "paypal") {
          let docRef = db
            .collection("paypal_subscription")
            .doc(current_subscription.id);
          docRef.get().then((subscriptionDocument) => {
            let subscription = subscriptionDocument.data();
            commit("setSubscription", subscription);
          });
        }
      }
    },

    getPayments({ commit, state }) {
      if (!state.userData) {
        return;
      }
      let current_subscription = state.userData.current_subscription;
      if (current_subscription) {
        if (current_subscription.payment_provider === "paypal") {
          let queryRef = db
            .collection("paypal_sale")
            .where("billing_agreement_id", "==", current_subscription.id)
            .orderBy("create_time", "desc");
          queryRef.get().then((querySnapshot) => {
            let payments = [];
            querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
              payments.push(doc.data());
            });

            commit("setPayments", payments);
          });
        }
      }
    },
    logout({ commit }) {
      auth
        .signOut()
        .then(() => {
          this.dispatch("unbindUser");
          commit("setUserLoggedOut");
          document.cookie = "__session=";
        })
        .catch(() => {
          this.dispatch("unbindUser");
          commit("setUserLoggedOut");
        })
        .then(() => {
          router.go({ name: "home" });
        });
    },
    resetPassword({ commit }, { email }) {
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          commit("setResetPasswordSentSuccess", true);
        })
        .catch(() => {
          commit("setResetPasswordSentSuccess", true);
        });
    },
    emailValidation({ commit }, hash) {
      // eslint-disable-next-line no-console
      //   console.log("emailValidation", hash);
      commit("setEmailVerified", {
        success: null,
        message: "prüfe",
      });
      axios
        .get(Settings.functionURLs.verifyUser + hash)
        .then(function(response) {
          // handle success
          // eslint-disable-next-line no-console
          //   console.log(response);
          if (response.status === 200) {
            commit("setEmailVerified", {
              success: true,
              message: "Vielen Dank!",
            });
          }
        })
        .catch(function(error) {
          // handle error
          // eslint-disable-next-line no-console
          console.error(error);
          commit("setEmailVerified", {
            success: false,
            message: "Fehler: " + error,
          });
        });
    },
    confirmNewsletter({ commit }, hash) {
      //   console.log("confirmNewsletter", hash);
      commit("setNewsletterConfirmed", {
        success: null,
        message: "prüfe",
      });
      axios
        .get(Settings.functionURLs.confirmNewsletterRegistration + hash)
        .then(function(response) {
          // handle success
          //   console.log(response);
          if (response.status === 200 && response.data.success) {
            commit("setNewsletterConfirmed", {
              success: true,
              message: "Vielen Dank!",
            });
            return;
          }
          commit("setNewsletterConfirmed", {
            success: false,
            message: "Da ist was schief gelaufen",
          });
        })
        .catch(function(error) {
          // handle error
          // eslint-disable-next-line no-console
          console.error(error);
          commit("setNewsletterConfirmed", {
            success: false,
            message: "Fehler: " + error,
          });
        });
    },

    // eslint-disable-next-line no-empty-pattern
    savePaypalSubscription({ commit }, { subscriptionId }) {
      commit("setProcessingSubscription", true);

      let savePaypalSubscriptionUrl =
        Settings.functionURLs.savePaypalSubscription;
      //   console.log(
      //     "Sending SubscriptionId",
      //     subscriptionId,
      //     savePaypalSubscriptionUrl
      //   );

      auth.currentUser.getIdToken().then(function(token) {
        var req = new XMLHttpRequest();

        req.onload = function() {
          // Dialog.alert({
          //   message: "Abo übertragen.",
          // });
        }.bind(this);

        req.onerror = function() {
          commit("setProcessingSubscription", false);
          Dialog.alert({
            message:
              "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
          });
        }.bind(this);

        req.open(
          "GET",
          savePaypalSubscriptionUrl + subscriptionId + "&token=" + token,
          true
        );

        req.send();
      });
    },

    // eslint-disable-next-line no-empty-pattern
    cancelSubscription({ state, commit }, { subscriptionId }) {
      // eslint-disable-next-line no-console
      //   console.log(subscriptionId);

      const subscription = {
        ...state.userData.current_subscription,
        cancelling: true,
      };
      commit("setUserData", {
        ...state.userData,
        current_subscription: subscription,
      });

      let cancelSubscriptionUrl = Settings.functionURLs.cancelSubscription;
      auth.currentUser.getIdToken().then(function(token) {
        var req = new XMLHttpRequest();

        req.onload = function() {}.bind(this);

        req.onerror = function() {
          Dialog.alert({
            message:
              "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
          });
        }.bind(this);

        req.open(
          "GET",
          cancelSubscriptionUrl + subscriptionId + "&token=" + token,
          true
        );

        req.send();
      });
    },
    // eslint-disable-next-line no-empty-pattern
    cancelPaypalSubscription({ state, commit }, { subscriptionId }) {
      // eslint-disable-next-line no-console
      //   console.log(subscriptionId);

      const subscription = {
        ...state.userData.current_subscription,
        cancelling: true,
      };
      commit("setUserData", {
        ...state.userData,
        current_subscription: subscription,
      });

      let cancelSubscriptionUrl =
        Settings.functionURLs.cancelPaypalSubscription;

      auth.currentUser.getIdToken().then((token) => {
        var req = new XMLHttpRequest();

        req.onload = function() {}.bind(this);

        req.onerror = function() {
          Dialog.alert({
            message:
              "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
          });
        }.bind(this);

        const cancelPaypalSubscriptionUrl =
          cancelSubscriptionUrl + subscriptionId + "&token=" + token;

        req.open("GET", cancelPaypalSubscriptionUrl, true);

        req.send();
      });
    },
    bindUser: firestoreAction(({ bindFirestoreRef }, payload) => {
      // return the promise returned by `bindFirestoreRef`
      if (payload && payload.user) {
        return bindFirestoreRef(
          "userData",
          db.collection("users").doc(payload.user.uid)
        );
      }
    }),
    bindSubscription: firestoreAction(({ bindFirestoreRef }, payload) => {
      if (
        payload &&
        payload.user &&
        payload.user.current_subscription &&
        payload.user.current_subscription.id
      ) {
        let subscription = payload.user.current_subscription;
        if (
          subscription.payment_provider &&
          subscription.payment_provider === "paypal"
        ) {
          return bindFirestoreRef(
            "bindSubscription",
            db.collection("paypal_subscription").doc(subscription.id)
          );
        }
      }
    }),
    unbindSubscription: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("subscription");
    }),

    unbindUser: firestoreAction(({ unbindFirestoreRef }) => {
      // return the promise returned by `unbindFirestoreRef`
      return unbindFirestoreRef("userData");
    }),
    initCheckout({ state, dispatch }) {
      // eslint-disable-next-line no-console
      //   console.log("actions", dispatch);
      if (state.checkout === null) {
        if (document.querySelector("script#_stripe-checkout-script")) {
          return dispatch("setCheckout");
        }
        const script = document.createElement("script");
        script.id = "_stripe-checkout-script";
        script.src = "https://js.stripe.com/v3/";
        script.onload = function() {
          dispatch("setCheckout");
        };
        document.querySelector("head").append(script);
      }
    },
    setCheckout({ commit, state }) {
      if (state.checkout === null) {
        if (typeof Stripe !== "undefined") {
          const stripe_pk = Settings.stripe.pk;

          // eslint-disable-next-line no-undef
          const lib = Stripe(stripe_pk, {
            locale: "de",
          });

          commit("setCheckout", lib);
          // eslint-disable-next-line no-console
          //   console.log("Stripe Checkout", this, this.checkout);
        }
      }
    },
  },
  getters: {
    currentView: (state) => state.currentView,
    isAuthenticated: (state) => state.authenticated,
    loginError: (state) => state.loginError,
    passwordForgotten: (state) => state.passwordForgotten,
    resetPasswordSentSuccess: (state) => state.resetPasswordSentSuccess,
    userData: (state) => state.userData,
    authUser: (state) => state.user,
    authToken: (state) => state.token,
    checkoutStep: (state) => state.checkoutStep,
    checkoutPlan: (state) => state.checkoutPlan,
    emailVerified: (state) => {
      return {
        success: state.emailVerifiedSuccess,
        message: state.emailVerifiedMessage,
      };
    },
    newsletterConfirmed: (state) => {
      return {
        success: state.newsletterConfirmedSuccess,
        message: state.newsletterConfirmedMessage,
      };
    },
    checkout: (state) => state.checkout,
    subscription: (state) => state.subscription,
    payments: (state) => state.payments,
    processingSubscription: (state) => state.processingSubscription,
    // auth: state => state.auth
  },
  plugins: [
    createPersistedState({
      paths: [
        "currentView",
        "user",
        "userData",
        "authenticated",
        "token",
        "emailVerifiedSuccess",
        "emailVerifiedMessage",
      ],
    }),
  ],
});
