import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import Buefy from "buefy";
import "./styles.scss";
import "buefy/dist/buefy.css";
import "bulma-extensions/bulma-pricingtable/dist/css/bulma-pricingtable.min.css";
import LoadScript from "vue-plugin-load-script";

Vue.use(LoadScript);

Vue.use(Buefy);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
