const stripe = {
  pk: process.env.VUE_APP_STRIPE_PK,
};

const paypal = {
  clientId: process.env.VUE_APP_PAYPAL_CLIENT_ID,
};

const functionURLs = {
  verifyUser: process.env.VUE_APP_VERIFY_USER_URL,
  confirmNewsletterRegistration:
    process.env.VUE_APP_CONFIRM_NEWSLETTER_REGISTRATION_URL,
  cancelSubscription: process.env.VUE_APP_CANCEL_SUBSCRIPTION_URL,
  cancelPaypalSubscription: process.env.VUE_APP_PAYPAL_CANCEL_SUBSCRIPTION_URL,
  savePaypalSubscription: process.env.VUE_APP_SAVE_PAYPAL_SUBSCRIPTION_URL,
};

const subscriptionPlans = [
  {
    name: "EiS Einzelabo",
    stripeId: "base-monthly",
    paypalId: process.env.VUE_APP_PAYPAL_PRODUCT_ID_SINGLE_MONTHLY,
    interval: "month",
    intervalName: "Monat",
    intervalAdjective: "monatlich",
    price: 4.95,
    devicesText: "ein Gerät",
    features: [
      "mit einem Gerät nutzbar",
      "monatlich kündbar",
      "mehr als 150 Wörter",
    ],
  },
  {
    name: "EiS Familienabo",
    stripeId: "family-monthly",
    paypalId: process.env.VUE_APP_PAYPAL_PRODUCT_ID_FAMILY_MONTHLY,
    interval: "month",
    intervalName: "Monat",
    intervalAdjective: "monatlich",
    price: 9.95,
    devicesText: "bis zu fünf Geräte",
    features: [
      "mit bis zu fünf Geräten nutzbar",
      "monatlich kündbar",
      "mehr als 150 Wörter",
    ],
  },
  {
    name: "EiS Einzelabo",
    stripeId: "base-yearly",
    paypalId: process.env.VUE_APP_PAYPAL_PRODUCT_ID_SINGLE_YEARLY,
    interval: "year",
    intervalName: "Jahr",
    intervalAdjective: "jährlich",
    price: 49.95,
    devicesText: "ein Gerät",
    features: [
      "mit einem Gerät nutzbar",
      "jährlich kündbar",
      "mehr als 150 Wörter",
    ],
  },
  {
    name: "EiS Familienabo",
    stripeId: "family-yearly",
    paypalId: process.env.VUE_APP_PAYPAL_PRODUCT_ID_FAMILY_YEARLY,
    intervalName: "Jahr",
    intervalAdjective: "jährlich",
    interval: "year",
    price: 99.95,
    devicesText: "bis zu fünf Geräte",
    features: [
      "mit bis zu fünf Geräten nutzbar",
      "jährlich kündbar",
      "mehr als 150 Wörter",
    ],
  },
];

module.exports = { subscriptionPlans, stripe, functionURLs, paypal };
