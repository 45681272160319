<template>
  <section>
    <div class="hero-body header">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column">
            <figure class="image is-128x128">
              <img class="is-rounded" src="../assets/logo.png" />
            </figure>
          </div>
          <div class="column is-11">
            <h1 class="title">
              <span>EiS</span>
            </h1>
            <h2 class="subtitle">
              <span>Eine inklusive Sprachlernapp</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot">
      <b-navbar>
        <template slot="start" v-if="isAuthenticated">
          <!-- <div v-if="isAuthenticated"> -->
          <b-navbar-item tag="router-link" :to="{ path: '/' }"
            >Home</b-navbar-item
          >
          <!-- <b-navbar-item tag="router-link" :to="{ path: '/account' }"
            >Account</b-navbar-item
          >-->
          <b-navbar-item tag="router-link" :to="{ path: '/app-download' }"
            >Download App</b-navbar-item
          >
          <!-- </div> -->
        </template>

        <template slot="end">
          <b-navbar-item tag="div">
            <div class="buttons">
              <a
                v-if="isAuthenticated"
                @click="logout()"
                class="button is-danger"
                >{{ userData.username }} Abmelden</a
              >
            </div>
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Header",

  computed: {
    ...mapGetters(["currentView", "isAuthenticated", "userData"]),
    showLoginButton() {
      return !this.isAuthenticated && this.$route.name !== "login";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
